/* eslint-disable no-param-reassign */
import React, { useEffect } from "react";

import { Chip } from "@material-ui/core";
import { v4 as uuid } from "uuid";
import { JointScenarioConnectionStatus } from "../../../../types/join-account";
import { getFullName } from "../../../../utils/user-data-util";
import ScenarioInviteInput from "./scenario-invite-input";

function updateObjectById(arr, id, updatedObject) {
  const index = arr.findIndex((obj) => obj.id === id);
  if (index !== -1) {
    arr[index] = { ...arr[index], ...updatedObject };
    return arr;
  }
  return arr;
}

const generateDefaultConnection = (amount) => {
  const connections = [...Array(amount).keys()].map(() => ({
    id: uuid(),
    email: "",
    status: "",
  }));
  return connections;
};

const ScenarioInivteGroup = ({
  setScenarioFilter = () => {},
  filterSelect = {},
  isOwner,
}) => {
  const { connections = [], applicant, scenarioId } = filterSelect;
  const numberSubmittedConnection = connections.filter(
    (connection) => connection.status
  ).length;
  const handleChangeValue = (key, value) => {
    setScenarioFilter({
      connections: updateObjectById(connections, key, { email: value }),
    });
  };
  useEffect(() => {
    if (applicant >= 2) {
      setScenarioFilter({
        connections: [
          ...connections,
          ...generateDefaultConnection(applicant - 1),
        ].slice(
          0,
          scenarioId
            ? Math.max(numberSubmittedConnection, applicant - 1)
            : applicant - 1
        ),
      });
    }
  }, [applicant]);

  if (applicant === 1) {
    return <></>;
  }

  const renderInput = () => (
    <>
      {connections?.map((e, i) => {
        if (i > applicant - 2) {
          return <></>;
        }
        return (
          <ScenarioInviteInput
            key={e.id}
            handleChange={(val) => handleChangeValue(e.id, val)}
            filterSelect={filterSelect}
            value={e?.email || ""}
            status={e?.status || ""}
            setScenarioFilter={setScenarioFilter}
            username={getFullName(e?.member)}
            connection={e}
          />
        );
      })}
    </>
  );
  const renderMember = () => (
    <div className="flex flex-col">
      {connections.map((e) => (
        <span key={e.email}>
          {e.email} ({getFullName(e?.member)})
          {e?.status === JointScenarioConnectionStatus.WAITING && (
            <Chip label="Waiting" size="small" />
          )}
        </span>
      ))}
    </div>
  );

  return (
    <div className="scenario-filter-finance">
      <div className="scenario-filter-title">
        {isOwner ? "Invite To Joint Scenario" : "Members"}
      </div>
      <div className="scenario-filter-item-container justify-start flex items-center">
        {isOwner ? renderInput() : renderMember()}
      </div>
    </div>
  );
};

export default ScenarioInivteGroup;
